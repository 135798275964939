.App {
  text-align: center;
  position: relative;
}

.slide {
  position: relative;
  width: 100%;
  height: 50vh;
}

.slide2 {
  position: relative;
  width: 100%;
}



@media screen and (min-width: 1200px) {
.slide {
  position: relative;
  width: 100%;
  height: 80vh;
}
}

.show {
  width: 100%;
  height: 100%;
  animation: fade 1.5s ease-in-out;
}

.show > div {
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: 100%;
}



/* @keyframes trans {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}  */

@keyframes fade {
  from {
    opacity: 0.75;
  }
  to {
    opacity: 1;
  }
}

.not-show {
  display: none;
}
