@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";



iframe#webpack-dev-server-client-overlay{display:none!important}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.css-yfo96e {
    padding: 9px 0 0 0 !important;
}

.css-h4y409-MuiList-root {
    padding-top: 0px !important;
}

label {
  font-weight: bold;
}

.fancy {
font-family: "verdigris-mvb-pro-big", serif;
font-weight: 700;
font-style: normal;
}

.section {
  padding: 2.5rem 0 2rem 0;
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu .rocky {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: white;
}

.view {
  z-index: 999999999999999;
}

.sticky-thc {
  position: sticky;
  top: 0;
  z-index: 999;
}

:focus-visible {
  outline: 0px;
}

.ia-container {
	width: 685px;
	margin: 20px auto;
	overflow: hidden;
	box-shadow: 1px 1px 4px rgba(0,0,0,0.08);
	border: 7px solid rgba(255,255,255,0.6);
}

.goog-te-gadget-simple {
    background-color: green !important;
    border-left: 0px solid #D5D5D5 !important;
    border-top: 0px solid #9B9B9B !important;
    border-bottom: 0px solid #E8E8E8 !important;
    border-right: 0px solid #D5D5D5 !important;
    font-size: 11pt !important;
    display: inline-block !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer !important;
    height: 40px !important;
    margin-top: -6px !important;
    margin-right: 20px !important;
}

.goog-te-gadget-icon {
  display: none;
}